<template>
  <v-container>
    <v-card>
      <v-toolbar color="primary" dense>
        <span>TIR</span>
        <v-spacer />
        <v-btn v-if="permissions.recalculate.includes(user.role)" small @click="$store.dispatch('settings/calculateHistoricalTIR')">
          Recalcular
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-5 black--text">
        <v-row v-if="tirList">
          <v-col cols="6">
            <p class="mb-0 text-h6 text-decoration-underline"><b>Oficinas:</b></p>
            <p v-for="office in _.keys(tirList.offices)" :key="office" class="mb-0">
              <b>{{ offices.find((e) => e.id === office)?.name }}:</b>
              {{ Math.round((tirList.offices[office].TIR + Number.EPSILON) * 100) / 100 }}%
            </p>
          </v-col>
          <v-col cols="6">
            <p class="mb-0 text-h6 text-decoration-underline"><b>Países:</b></p>
            <p v-for="country in _.keys(tirList.countries)" :key="country" class="mb-0">
              <b>{{ countries.find((e) => e.id === country)?.name }}:</b>
              {{ Math.round((tirList.countries[country] + Number.EPSILON) * 100) / 100 }}%
            </p>
          </v-col>
        </v-row>
        <p v-else class="text-center mb-0 pa-3 text--secondary">No hay datos disponibles</p>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      // Actions permissions
      permissions: {
        recalculate: ["SUPERADMIN"]
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      offices: (state) => state.offices.offices,
      countries: (state) => state.locations.countries,
      tirList: (state) => state.settings.tirList
    }),
    _() {
      return _;
    }
  },
  async created() {
    await this.$store.dispatch("settings/getTirList");
    await this.$store.dispatch("locations/getCountries");
  }
};
</script>
