import { storage } from "@/firebase";
import * as currency from "currency.js";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import { getExchangeRate, convertCurrency as convertCurrencyExchange } from "@davidrebollo92/currency-exchange";

export default {
  mixins: [FormatDateMixin],
  methods: {
    async getFileFromStorage(path) {
      const storageRef = storage.ref();
      const url = await storageRef
        .child(path)
        .getDownloadURL()
        .catch(() => {});

      return url;
    },

    // Get url of storage file and open in new tab
    async getUrlAndOpen(path) {
      this.$store.commit("SET_LOADING", true);
      const url = await this.getFileFromStorage(path);
      window.open(url, "_blank");
      this.$store.commit("SET_LOADING", false);
    },

    // Return if value is empty
    isEmptyValues(value) {
      return (
        value === undefined ||
        value === null ||
        (typeof value === "object" && Object.keys(value).length === 0) ||
        (typeof value === "string" && value.trim().length === 0)
      );
    },

    cleanObject(o) {
      for (var k in o) {
        if (typeof o[k] !== "object") {
          if (this.isEmptyValues(o[k])) delete o[k];
        } else {
          // The property is an object
          this.cleanObject(o[k]); // <-- Make a recursive call on the nested object
          if (Object.keys(o[k]).length === 0) {
            delete o[k]; // The object had no properties, so delete that property
          }
        }
      }
      return o;
    },

    // Return number reounded with 2 decimals
    round(num) {
      return Math.round(num * 100 + Number.EPSILON) / 100;
    },

    // Return a random id
    makeid(length) {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    },

    // Convert currency from one currency to another
    convertCurrency(amount, from, to) {
      const rates = this.$store.state.currencies.rates;
      const fromRate = rates[from.toUpperCase()];
      const toRate = rates[to.toUpperCase()];
      const rate = getExchangeRate(fromRate, toRate);
      const amountConverted = convertCurrencyExchange(amount.intValue, rate);
      return currency(amountConverted, { fromCents: true });
    },

    // Convert amount with custom rate
    convertAmount(amount, rateValue) {
      const amountConverted = convertCurrencyExchange(amount.intValue, rateValue);
      return currency(amountConverted, { fromCents: true });
    },

    getDefaultExchangeRate(from, to) {
      const rates = this.$store.state.currencies.rates;
      const defaultExchangeRate = (1 * rates[to.toUpperCase()]) / rates[from.toUpperCase()];
      return defaultExchangeRate;
    },

    // Convert currency from decimals to int
    convertToInt(value) {
      return currency(value).intValue;
    },

    // Convert currency from int to decimal
    convertToDecimal(value) {
      return value.value;
    },

    nextDateOfTheLastMoratorium(lastMoratoriumDate) {
      const nextDate = {
        month: lastMoratoriumDate.month + 1,
        year: lastMoratoriumDate.year
      };

      if (nextDate.month === 13) {
        nextDate.month = 1;
        nextDate.year = nextDate.year + 1;
      }
      return nextDate;
    },

    // Sort array of dates desc
    sortArrayDates(arr) {
      return arr.sort((a, b) => {
        a = new Date(a.year, a.month);
        b = new Date(b.year, b.month);

        return a < b ? -1 : a > b ? 1 : 0;
      });
    },

    // Transform Object to Array and save the key as id
    objectToArray(obj) {
      for (const key in obj) {
        obj[key].id = key;
      }
      return this._.values(obj);
    },

    // Transform file to base64
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const aux = reader.result.split(",");
          resolve(aux[1]);
        };
        reader.onerror = reject;
      });
    }
  }
};
