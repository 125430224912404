<template>
  <v-container>
    <v-card>
      <v-toolbar color="primary" dense>
        <span>Monedas</span>
        <v-spacer />
        <v-btn v-if="permissions.add.includes(user.role)" small @click="dialogAddCurrency = true">
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <DataTable :headers="tableHeaders" :items="currencies" :hideDefaultFooter="false">
        <template v-if="permissions.edit.includes(user.role)" v-slot:[`item.toggle_enable`]="{ item }">
          <v-btn
            :color="item.investable ? 'primary' : 'secondary'"
            x-small
            class="black--text mr-2"
            @click="!item.investable ? currencyToInvestable(item.id) : ''"
          >
            Sí
          </v-btn>
          <v-btn
            :color="!item.investable ? 'primary' : 'secondary'"
            x-small
            class="black--text"
            :disabled="item.id === 'EUR'"
            @click="item.investable ? currencyToUninvestable(item.id) : ''"
          >
            No
          </v-btn>
        </template>
        <template v-if="permissions.edit.includes(user.role)" v-slot:[`item.actions`]="{ item }">
          <v-icon @click="setCurrencySelected(item), (dialogEditCurrency = true)">edit</v-icon>
        </template>
      </DataTable>
    </v-card>

    <!-- Dialog add currency -->
    <DialogBox :model="dialogAddCurrency">
      <template slot="toolbar">
        <span>Añadir moneda</span>
      </template>
      <template slot="content">
        <v-form ref="formAddCurrency" v-model="formAddCurrencyValid">
          <v-autocomplete :items="_.keys(rates)" v-model="newCurrency.id" label="Código" :rules="formRules.textRules" />
          <v-text-field v-model="newCurrency.name" label="Nombre" :rules="formRules.textRules" />
          <v-text-field v-model="newCurrency.symbol" label="Símbolo" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogAddCurrency', 'formAddCurrency')">Cerrar</v-btn>
        <v-btn text @click="createCurrency()">Crear</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog edit currency -->
    <DialogBox :model="dialogEditCurrency">
      <template slot="toolbar">
        <span>Editar moneda</span>
      </template>
      <template slot="content">
        <v-form ref="formEditCurrency" v-model="formEditCurrencyValid">
          <v-text-field v-model="currencySelected.name" label="Nombre" :rules="formRules.textRules" />
          <v-text-field v-model="currencySelected.symbol" label="Símbolo" :rules="formRules.textRules" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogEditCurrency', 'formEditCurrency')">Cerrar</v-btn>
        <v-btn text @click="editCurrency()">Editar</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DataTable from "@/components/elements/DataTable";
import DialogBox from "@/components/elements/DialogBox";
import _ from "lodash";

export default {
  components: {
    DataTable,
    DialogBox
  },
  mixins: [FormRulesMixin],
  data() {
    return {
      // Actions permissions
      permissions: {
        add: ["SUPERADMIN"],
        edit: ["SUPERADMIN"]
      },

      // Currencies datatable
      tableHeaders: [
        { text: "Nombre", value: "name", sortable: false },
        { text: "Símbolo", value: "symbol", sortable: false },
        { text: "Disponible para invertir", value: "toggle_enable", sortable: false },
        { text: "", value: "actions", sortable: false }
      ],

      newCurrency: {},
      currencySelected: {},

      // Dialogs
      dialogAddCurrency: false,
      formAddCurrencyValid: false,
      dialogEditCurrency: false,
      formEditCurrencyValid: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      rates: (state) => state.currencies.rates,
      currencies: (state) => state.currencies.currencies
    }),
    _() {
      return _;
    }
  },
  async created() {
    await this.$store.dispatch("currencies/getCurrencies");
  },
  methods: {
    ...mapActions({
      currencyToInvestable: "currencies/currencyToInvestable",
      currencyToUninvestable: "currencies/currencyToUninvestable"
    }),

    async createCurrency() {
      if (this.$refs.formAddCurrency.validate()) {
        await this.$store.dispatch("currencies/createCurrency", this.newCurrency);
        await this.$store.dispatch("currencies/getCurrencies");
        this.closeDialog("dialogAddCurrency", "formAddCurrency");
      }
    },

    async editCurrency() {
      if (this.$refs.formEditCurrency.validate()) {
        await this.$store.dispatch("currencies/editCurrency", this.currencySelected);
        await this.$store.dispatch("currencies/getCurrencies");
        this.closeDialog("dialogEditCurrency", "formEditCurrency");
      }
    },

    setCurrencySelected(item) {
      this.currencySelected.id = item.id;
      this.currencySelected.name = item.name;
      this.currencySelected.symbol = item.symbol;
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.newCurrency = this.$options.data().newCurrency;
      this.currencySelected = this.$options.data().currencySelected;
    }
  }
};
</script>
