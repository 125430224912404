<template>
  <v-container>
    <v-card>
      <v-toolbar color="primary" dense>
        <span>Versiones de parámetros de inversión</span>
        <v-spacer />
        <v-btn v-if="permissions.add.includes(user.role)" small @click="handlerAddVersionBtn()">
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-5">
        <v-row>
          <v-col v-for="(version, i) in investmentsSettingsVersions" :key="i" cols="12" md="3">
            <v-card class="py-5" :class="version.value.status.active ? 'version_active' : ''" rounded="lg" height="100%">
              <div class="text-center">
                <p class="text-h6 mb-0">{{ version.key }}</p>
                <p v-if="version.value.status.description">{{ version.value.status.description }}</p>
                <p class="font-italic mt-3">Fecha de creación: {{ formatDateL(version.value.status.created_at) }}</p>
                <p v-if="version.value.status.active" class="primary--text">Versión activa</p>
                <v-btn color="primary" small @click="handlerShowVersionBtn(version)"> Ver versión </v-btn>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Dialog show version -->
    <DialogBox v-if="dialogShowVersion" :model="dialogShowVersion" :max_width="'1000'">
      <template slot="toolbar">
        <span>Versión: {{ investmentsSettingsVersionSelected.key }}</span>
      </template>
      <template slot="content">
        <p v-if="investmentsSettingsVersionSelected.value.status.description">
          <b>Descripción:</b> {{ investmentsSettingsVersionSelected.value.status.description }}
        </p>
        <p class="mb-0"><b>Comisiones:</b></p>
        <div v-for="(commission, i) in investmentsSettingsVersionSelected.value.data.commissions" :key="i" class="mb-0">
          <u>{{ commission.name }}:</u>
          <ul>
            <li v-for="(office_commision, i) in commission.values" :key="i">
              {{ offices.find((e) => e.id === i).name }}: {{ office_commision }}%
            </li>
          </ul>
        </div>
        <p class="mb-0 mt-3"><b>Reinversión:</b></p>
        <v-simple-table dense class="mb-10">
          <template v-slot:default>
            <thead>
              <tr>
                <th><h3>Oficina</h3></th>
                <th><h3>Oficinas aptas para reinversión</h3></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(office, key) in investmentsSettingsVersionSelected.value.data.reinvestment" :key="office.id">
                <td>{{ offices.find((e) => e.id === key).name }}</td>
                <td>
                  <v-chip v-for="officeAllowedToReinvesment in office" :key="officeAllowedToReinvesment" small class="mr-1">
                    {{ offices.find((e) => e.id === officeAllowedToReinvesment).name }}
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <p class="mb-0 mt-3"><b>Cantidad mínima de depósito:</b></p>
        <p v-for="(min_amount, i) in investmentsSettingsVersionSelected.value.data.min_amounts_to_deposit" :key="i" class="mb-0">
          {{ i }}: {{ min_amount }}
        </p>
        <p class="font-italic mt-3">Fecha de creación: {{ formatDateL(investmentsSettingsVersionSelected.value.status.created_at) }}</p>
        <v-btn
          v-if="!investmentsSettingsVersionSelected.value.status.active"
          color="primary"
          small
          @click="formConfirmActiveVersion = true"
        >
          Activar
        </v-btn>
      </template>
      <template slot="actions">
        <v-btn text @click="dialogShowVersion = false">Cerrar</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog add investments settings version -->
    <DialogBox v-if="dialogAddInvestmentsSettingsVersion" :model="dialogAddInvestmentsSettingsVersion" :max_width="'1000'">
      <template slot="toolbar">
        <span>Añadir parámetro de inversión</span>
      </template>
      <template slot="content">
        <v-form ref="formAddInvestmentsSettingsVersion" v-model="formAddInvestmentsSettingsVersionValid">
          <v-text-field v-model="investmentsSettingsVersionNew.key" label="Nombre" :rules="formRules.textRules" />
          <v-textarea
            v-model="investmentsSettingsVersionNew.value.status.description"
            rows="1"
            auto-grow
            label="Descripción"
            :rules="formRules.textRules"
          />

          <!-- Commissions -->
          <p><b>Comisiones:</b></p>
          <v-row>
            <v-col
              v-for="(commission, commissionId) in investmentsSettingsVersionNew.value.data.commissions"
              :key="commissionId"
              cols="12"
              md="6"
            >
              <div class="secondary pa-5">
                <v-text-field
                  v-model="commission.name"
                  label="Nombre"
                  :disabled="commissionId === 'DEFAULT'"
                  :rules="formRules.textRules"
                />
                <v-row v-for="office in offices" :key="office.id" class="d-flex align-center">
                  <v-col cols="5">
                    <p class="text-subtitle-1 mb-0">
                      <b>{{ office.name }}:</b>
                    </p>
                  </v-col>
                  <v-col cols="7">
                    <v-text-field
                      v-model.number="commission.values[office.id]"
                      label="Porcentaje"
                      class="mb-n5"
                      type="number"
                      outlined
                      solo
                      suffix="%"
                      dense
                      :rules="formRules.numberRulesAllowZero"
                    />
                  </v-col>
                </v-row>
                <v-checkbox v-model="commission.disabled" label="Deshabilitar" :disabled="commissionId === 'DEFAULT'" />
              </div>
            </v-col>
          </v-row>
          <div class="text-right my-5">
            <v-btn elevation="2" small @click="addCommission">
              <v-icon>add</v-icon>
            </v-btn>
          </div>

          <!-- Reinvestment -->
          <p><b>Reinversión:</b></p>
          <v-simple-table dense class="mb-10">
            <template v-slot:default>
              <thead>
                <tr>
                  <th><h3>Oficina</h3></th>
                  <th><h3>Oficinas aptas para reinversión</h3></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="office in offices" :key="office.id">
                  <td>{{ office.name }}</td>
                  <td>
                    <v-select
                      v-model="investmentsSettingsVersionNew.value.data.reinvestment[office.id]"
                      :items="offices.filter((e) => e.id !== office.id)"
                      item-text="name"
                      item-value="id"
                      :menu-props="{ maxHeight: '400' }"
                      multiple
                      chips
                      hide-details
                      filled
                      single-line
                      label="Selecciona oficinas"
                      dense
                      class="ma-1"
                      @change="$forceUpdate()"
                    >
                      <template v-slot:selection="{ item }">
                        <v-chip text-color="black" small>{{ item.name }}</v-chip>
                      </template>
                      <template v-slot:item="{ item, attrs, on }">
                        <v-list-item
                          v-on="on"
                          v-bind="attrs"
                          #default="{ active }"
                          @change="handleReinvestmentsOffices(office.id, item.id)"
                        >
                          <v-list-item-action>
                            <v-checkbox :input-value="active" />
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              <v-row no-gutters align="center">
                                <span>{{ item.name }}</span>
                              </v-row>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- Remaining percentage to hide projects  -->
          <p><b>Porcentaje mínimo restante para ocultar proyectos:</b></p>
          <v-text-field
            v-model.number="investmentsSettingsVersionNew.value.data.percentage_hide_projects"
            type="number"
            label="Porcentaje"
            dense
            :rules="formRules.numberRules"
          />

          <!-- Min investments -->
          <p><b>Cantidad mínima de depósito:</b></p>
          <div
            v-for="(min_amount, i) in investmentsSettingsVersionNew.value.data.min_amounts_to_deposit"
            :key="i"
            class="secondary pa-5 mb-2"
          >
            <div class="text-right">
              <v-btn
                v-if="
                  !(
                    i in
                    investmentsSettingsVersions.find((e) => e.key === investmentsSettingsVersionActive.key).value.data
                      .min_amounts_to_deposit
                  )
                "
                icon
                x-small
                color="red"
                @click="removeCurrency(i)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </div>
            <v-row>
              <v-col cols="9">
                <v-text-field
                  v-model.number="investmentsSettingsVersionNew.value.data.min_amounts_to_deposit[i]"
                  type="number"
                  label="Cantidad mínima"
                  dense
                  :rules="formRules.numberRules"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  v-if="
                    i in
                    investmentsSettingsVersions.find((e) => e.key === investmentsSettingsVersionActive.key).value.data
                      .min_amounts_to_deposit
                  "
                  :value="i"
                  label="Moneda"
                  dense
                  readonly
                />
                <v-select
                  v-else
                  :value="currencies.find((e) => e.key === i)"
                  :items="
                    currencies.filter((e) => !(e.key in investmentsSettingsVersionNew.value.data.min_amounts_to_deposit) || e.key === i)
                  "
                  item-text="key"
                  label="Moneda"
                  return-object
                  :readonly="
                    i in
                    investmentsSettingsVersions.find((e) => e.key === investmentsSettingsVersionActive.key).value.data
                      .min_amounts_to_deposit
                  "
                  :rules="[(v) => !_.isEmpty(v) || 'Campo obligatorio']"
                  dense
                  @change="changeCurrency($event, i)"
                />
              </v-col>
            </v-row>
          </div>
          <div
            v-if="_.keys(investmentsSettingsVersionNew.value.data.min_amounts_to_deposit).length !== currencies.length"
            class="text-right"
          >
            <v-btn elevation="2" small @click="addCurrency">
              <v-icon>add</v-icon>
            </v-btn>
          </div>
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogAddInvestmentsSettingsVersion', 'formAddInvestmentsSettingsVersion')">Cerrar</v-btn>
        <v-btn text @click="actionAddInvestmentsSettingsVersion()">Añadir</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog confirm active version -->
    <DialogBox :model="formConfirmActiveVersion" :color="'warning'" isdark>
      <template slot="toolbar">
        <span>Cambiar versión</span>
      </template>
      <template slot="content">
        <b>¿Estás seguro de activar esta versión de parámetros de inversión?</b>
      </template>
      <template slot="actions">
        <v-btn text @click="formConfirmActiveVersion = false">Cerrar</v-btn>
        <v-btn
          text
          @click="
            activateInvestmentSettings(investmentsSettingsVersionSelected.key),
              (formConfirmActiveVersion = false),
              (dialogShowVersion = false)
          "
        >
          Activar
        </v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import CommonMixin from "@/mixins/CommonMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import DialogBox from "@/components/elements/DialogBox";
import _ from "lodash";

export default {
  components: {
    DialogBox
  },
  mixins: [CommonMixin, FormatDateMixin, FormRulesMixin],
  data() {
    return {
      test: {},
      // Actions permissions
      permissions: {
        add: ["SUPERADMIN"]
      },

      // Currencies datatable
      tableHeaders: [
        { text: "Nombre", value: "value.name", sortable: false },
        { text: "Símbolo", value: "value.symbol", sortable: false },
        { text: "", value: "actions", sortable: false }
      ],

      investmentsSettingsVersionSelected: {},
      investmentsSettingsVersionActive: {},
      investmentsSettingsVersionNew: {},

      // Dloag add AddInvestmentsSetting
      dialogShowVersion: false,
      dialogAddInvestmentsSettingsVersion: false,
      formAddInvestmentsSettingsVersionValid: false,
      formConfirmActiveVersion: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      investmentsSettingsVersions: (state) => state.investments.investmentsSettingsVersions,
      currencies: (state) => state.currencies.currencies,
      offices: (state) => state.offices.offices
    }),
    _() {
      return _;
    }
  },
  async created() {
    await this.$store.dispatch("investments/getInvestmentsSettingsVersions");
    await this.$store.dispatch("offices/getOffices");
  },
  methods: {
    ...mapActions({
      createNewInvestmentSettings: "investments/createNewInvestmentSettings",
      activateInvestmentSettings: "investments/activateInvestmentSettings"
    }),

    async actionAddInvestmentsSettingsVersion() {
      if (this.$refs.formAddInvestmentsSettingsVersion.validate()) {
        const investmentsSettingsVersionSelectedEditableFormattedData = {
          version: this.investmentsSettingsVersionNew.key,
          description: this.investmentsSettingsVersionNew.value.status.description,
          commissions: this.investmentsSettingsVersionNew.value.data.commissions,
          min_amounts_to_deposit: this.investmentsSettingsVersionNew.value.data.min_amounts_to_deposit,
          percentage_hide_projects: this.investmentsSettingsVersionNew.value.data.percentage_hide_projects,
          reinvestment: this.investmentsSettingsVersionNew.value.data.reinvestment
        };

        this.test = investmentsSettingsVersionSelectedEditableFormattedData;

        await this.createNewInvestmentSettings(investmentsSettingsVersionSelectedEditableFormattedData);
        this.closeDialog("dialogAddInvestmentsSettingsVersion", "formAddInvestmentsSettingsVersion");
      }
    },

    // Clone version active to investmentsSettingsVersionActive object
    cloneVersion() {
      const versionActive = this.investmentsSettingsVersions.find((e) => e.value.status.active);
      this.investmentsSettingsVersionActive = this._.cloneDeep(versionActive);
      this.investmentsSettingsVersionNew = this._.cloneDeep(versionActive);
      this.investmentsSettingsVersionNew.key = "";
      this.investmentsSettingsVersionNew.value.status.description = "";

      if (!versionActive.value.data.reinvestment) {
        const reinvestmentAux = {};
        this.offices.forEach((e) => {
          reinvestmentAux[e.id] = [e.id];
        });
        this.investmentsSettingsVersionNew.value.data.reinvestment = reinvestmentAux;
      } else {
        this.offices.forEach((e) => {
          if (!(e.id in versionActive.value.data.reinvestment)) this.investmentsSettingsVersionNew.value.data.reinvestment[e.id] = [e.id];
        });
      }
    },

    // Add new commmission in new investments settings version
    addCommission() {
      const id = this.makeid(20);
      const params = {
        disabled: false,
        name: "",
        values: {}
      };
      for (const office of this._.values(this.offices)) {
        params.values[office.id] = 0;
      }
      this.$set(this.investmentsSettingsVersionNew.value.data.commissions, [id], params);
    },

    // Add new currency in min amounts invested
    addCurrency() {
      let min_amounts_to_deposit = this.investmentsSettingsVersionNew.value.data.min_amounts_to_deposit;
      this.$set(min_amounts_to_deposit, ["key"], 50);
    },

    // Add new currency in min amounts invested
    removeCurrency(key) {
      this.$delete(this.investmentsSettingsVersionNew.value.data.min_amounts_to_deposit, [key]);
    },

    // Change currency key of min amount
    changeCurrency(currencySelected, key) {
      if (currencySelected.key !== key) {
        let min_amount = this.investmentsSettingsVersionNew.value.data.min_amounts_to_deposit;
        min_amount[currencySelected.key] = min_amount[key];
        this.$delete(min_amount, [key]);
      }
    },

    // Select/Deselect the offices ids on each office
    handleReinvestmentsOffices(officeChangedId, officeSelectedId) {
      const officeSelected = this.investmentsSettingsVersionNew.value.data.reinvestment[officeSelectedId];

      if (officeSelected.includes(officeChangedId)) {
        const index = officeSelected.indexOf(officeChangedId);
        officeSelected.splice(index, 1);
      } else officeSelected.push(officeChangedId);
    },

    handlerShowVersionBtn(version) {
      this.investmentsSettingsVersionSelected = version;
      this.dialogShowVersion = true;
    },

    handlerAddVersionBtn() {
      this.cloneVersion();
      this.dialogAddInvestmentsSettingsVersion = true;
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      if (formRef !== "") this.$refs[formRef].resetValidation();
      this[dialogModel] = false;
    }
  }
};
</script>

<style scoped>
.version_active {
  border: 3px solid #00f9ad;
}

tbody tr:hover {
  background-color: transparent !important;
}

::v-deep .theme--light.v-chip:not(.v-chip--active) {
  background: #00f9ad;
}
</style>
