<template>
  <v-container>
    <!-- Banner header -->
    <Banner :title="'Ajustes generales'">
      <template v-slot:actions>
        <GeneralConditions />
      </template>
    </Banner>

    <!-- Know know us section -->
    <HowKnowUs />

    <!-- Currencies section -->
    <Currencies />

    <!-- Investments settings section -->
    <InvestmentsSettings />

    <!-- TIR data section -->
    <Tir />

    <!-- Exchange rates table -->
    <ExchangeRates />
  </v-container>
</template>

<script>
import Banner from "@/components/elements/Banner";
import HowKnowUs from "@/components/elements/settings/HowKnowUs.vue";
import Currencies from "@/components/elements/settings/Currencies.vue";
import InvestmentsSettings from "@/components/elements/settings/InvestmentsSettings.vue";
import Tir from "@/components/elements/settings/Tir.vue";
import GeneralConditions from "@/components/elements/settings/GeneralConditions.vue";
import ExchangeRates from "@/components/elements/settings/ExchangeRates.vue";

export default {
  name: "Settings",
  components: {
    Banner,
    HowKnowUs,
    Currencies,
    InvestmentsSettings,
    Tir,
    GeneralConditions,
    ExchangeRates
  },
  async created() {
    await this.$store.dispatch("currencies/getCurrencies");
  }
};
</script>
