<template>
  <v-container>
    <v-card>
      <v-toolbar color="primary" dense>
        <span>TIPOS DE CAMBIO</span>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-simple-table fixed-header dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="black--text pt-3">
                  <DatePicker
                    :parent_model="date"
                    label="Fecha"
                    :required="true"
                    :max="dateNowFormated()"
                    :no_title="true"
                    :clearable="false"
                    @change="updateExchangeRatesDate"
                  />
                </th>
                <th v-for="currency in currencies" :key="currency.id" class="secondary">
                  <b>{{ currency.name }}</b>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="currency1 in currencies" :key="currency1.id">
                <td class="secondary">{{ currency1.name }}</td>
                <td v-for="currency2 in currencies" :key="currency2.id">
                  {{ getRate(currency1.id, currency2.id).toFixed(6) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import DatePicker from "@/components/elements/DatePicker";
import FormatDateMixin from "@/mixins/FormatDateMixin";

export default {
  name: "Exchange-rates",
  components: { DatePicker },
  mixins: [FormatDateMixin],
  data() {
    return {
      rates: [],
      date: this.formatDate()
    };
  },
  computed: {
    ...mapState({
      currencies: (state) => state.currencies.currencies
    })
  },
  async created() {
    const { data } = await axios.get(`${process.env.VUE_APP_HOST_CURRENCYEXCHANGER}/rates?date=${this.date}`);
    this.rates = data.data.rates;
  },
  methods: {
    getRate(from, to) {
      const defaultExchangeRate = (1 * this.rates[to.toUpperCase()]) / this.rates[from.toUpperCase()];
      return defaultExchangeRate;
    },

    async updateExchangeRatesDate(value) {
      this.date = value;
      const { data } = await axios.get(`${process.env.VUE_APP_HOST_CURRENCYEXCHANGER}/rates?date=${this.date}`);
      this.rates = data.data.rates;
    }
  }
};
</script>
