<template>
  <div>
    <v-btn small @click="generalConditionsDialog = true">Condiciones generales</v-btn>

    <!-- Dialog general conditions -->
    <DialogBox :model="generalConditionsDialog">
      <template slot="toolbar">
        <span>Condiciones generales</span>
      </template>
      <template slot="content">
        <div class="text-center">
          <v-btn outlined @click="openGeneralConditionsDocument()">
            <v-img :src="require(`@/assets/icons/download_pdf.png`)" max-width="24" class="mr-2" />
            Ver condiciones
          </v-btn>
        </div>
        <v-form
          v-if="permissions.edit.includes($store.state.user.user.role)"
          ref="formUpdateGeneralConditions"
          v-model="formUpdateGeneralConditionsValid"
          class="mt-4"
        >
          <p>Actualizar condiciones generales:</p>
          <v-textarea v-model="generalConditionsDescription" rows="4" filled label="Descripción" :rules="formRules.textRules" />
          <v-file-input
            v-model="document"
            accept="application/pdf"
            truncate-length="100"
            :clearable="false"
            prepend-icon="attach_file"
            label="Adjuntar documento"
            :rules="formRules.imageRequiredRules"
            class="pa-0"
          />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="generalConditionsDialog = false">Cerrar</v-btn>
        <v-btn v-if="permissions.edit.includes($store.state.user.user.role)" text @click="updateGeneralConditions()">Actualizar</v-btn>
      </template>
    </DialogBox>
  </div>
</template>

<script>
import FormRulesMixin from "@/mixins/FormRulesMixin";
import CommonMixin from "@/mixins/CommonMixin";
import DialogBox from "@/components/elements/DialogBox";

export default {
  components: {
    DialogBox
  },
  mixins: [CommonMixin, FormRulesMixin],
  data() {
    return {
      // Actions permissions
      permissions: {
        edit: ["SUPERADMIN"]
      },

      generalConditionsDescription: "",
      document: null,
      formUpdateGeneralConditionsValid: false,
      generalConditionsDialog: false
    };
  },
  methods: {
    async updateGeneralConditions() {
      if (this.$refs.formUpdateGeneralConditions.validate()) {
        const file = await this.toBase64(this.document);

        await this.$store.dispatch("settings/updateGeneralConditions", { description: this.generalConditionsDescription, file });

        this.generalConditionsDialog = false;
        this.document = null;
        this.generalConditionsDescription = "";
        this.$refs.formUpdateGeneralConditions.resetValidation();
      }
    },

    async openGeneralConditionsDocument() {
      const { data } = await this.$store.dispatch("settings/getGeneralConditions");
      if (data.success) await this.getUrlAndOpen(data.data.path);
    }
  }
};
</script>
