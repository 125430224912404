<template>
  <v-container>
    <v-card class="mt-8">
      <v-toolbar color="primary" dense>
        <span>Opciones de "Cómo nos conoció"</span>
        <v-spacer />
        <v-btn v-if="permissions.add.includes(user.role)" small @click="dialogAddHowKnowUsOption = true">
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-5">
        <v-chip-group v-if="howKnowUsOptions.length > 0" active-class="primary--text" column>
          <v-chip
            v-for="(option, i) in howKnowUsOptions"
            :key="i"
            :color="option.value.active ? 'primary' : ''"
            active-class=""
            @click="(howKnowUsOptionSelected = option), (dialogEditHowKnowUsOption = permissions.edit.includes(user.role))"
          >
            {{ option.value.name }}
          </v-chip>
        </v-chip-group>
        <p v-else class="text-center mb-0 pa-3 text--secondary">No hay datos disponibles</p>
      </v-card-text>
    </v-card>

    <!-- Dialog add howKnowUs option -->
    <DialogBox :model="dialogAddHowKnowUsOption">
      <template slot="toolbar">
        <span>Añadir opción a "cómo nos conoció"</span>
      </template>
      <template slot="content">
        <v-form ref="formAddHowKnowUsOption" v-model="formAddHowKnowUsOptionValid" @submit.prevent="">
          <v-text-field v-model="newHowKnowUsOption" label="Nombre" :rules="formRules.textRules" @keydown.enter="actionAddHowKnowUs()" />
        </v-form>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogAddHowKnowUsOption', 'formAddHowKnowUsOption')">Cerrar</v-btn>
        <v-btn text @click="actionAddHowKnowUs()">Añadir</v-btn>
      </template>
    </DialogBox>

    <!-- Dialog edit howKnowUs option -->
    <DialogBox v-if="!_.isEmpty(howKnowUsOptionSelected)" :model="dialogEditHowKnowUsOption">
      <template slot="toolbar">
        <span>
          Editar opción <b>{{ howKnowUsOptionSelected.value.name }}</b> de "cómo nos conoció"
        </span>
      </template>
      <template slot="content">
        <div class="text-center">
          <v-btn :color="howKnowUsOptionSelected.value.active ? 'error' : 'primary'" @click="actionEditHowKnowUs()">
            {{ howKnowUsOptionSelected.value.active ? "Desactivar" : "Activar" }}
          </v-btn>
        </div>
      </template>
      <template slot="actions">
        <v-btn text @click="closeDialog('dialogEditHowKnowUsOption', '')">Cerrar</v-btn>
      </template>
    </DialogBox>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import FormRulesMixin from "@/mixins/FormRulesMixin";
import _ from "lodash";
import DialogBox from "@/components/elements/DialogBox";

export default {
  components: {
    DialogBox
  },
  mixins: [FormRulesMixin],
  data() {
    return {
      // Actions permissions
      permissions: {
        add: ["SUPERADMIN"],
        edit: ["SUPERADMIN"]
      },

      // Add howKnowUsOption
      formAddHowKnowUsOptionValid: false,
      newHowKnowUsOption: "",

      // Edit howKnowUsOption
      howKnowUsOptionSelected: {},

      // Dialogs
      dialogAddHowKnowUsOption: false,
      dialogEditHowKnowUsOption: false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      howKnowUsOptions: (state) => state.settings.howKnowUsOptions
    }),
    _() {
      return _;
    }
  },
  async created() {
    await this.$store.dispatch("settings/getHowKnowUsOptions");
  },
  methods: {
    async actionAddHowKnowUs() {
      if (this.$refs.formAddHowKnowUsOption.validate()) {
        await this.$store.dispatch("settings/addHowKnowUs", this.newHowKnowUsOption);
        this.closeDialog("dialogAddHowKnowUsOption", "formAddHowKnowUsOption");
      }
    },

    async actionEditHowKnowUs() {
      await this.$store.dispatch("settings/editHowKnowUs", {
        id: this.howKnowUsOptionSelected.key,
        active: this.howKnowUsOptionSelected.value.active ? false : true
      });

      this.closeDialog("dialogEditHowKnowUsOption", "");
    },

    // Close dialogs, clear and reset validation form fields
    closeDialog(dialogModel, formRef) {
      if (formRef !== "") this.$refs[formRef].resetValidation();
      this[dialogModel] = false;

      this.newHowKnowUsOption = "";
    }
  }
};
</script>
